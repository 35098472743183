<template>
  <div class="content-text-component">
    <WYSISYWEditor v-model="text" />
  </div>
</template>

<script>
import WYSISYWEditor from '../WYSIWYG';
export default {
  name: 'ContentText',

  components: { WYSISYWEditor },

  props: {
    value: {
      type: String,
    },
  },

  data: () => ({
    text: null,
  }),

  watch: {
    value: {
      deep: true,
      handler() {
        this.text = this.value;
      },
    },
    text() {
      this.$emit('input', this.text);
    },
  },

  created() {
    this.text = this.value;
  },
};
</script>
