<template>
  <material-card
    color="primary"
    :heading="$t('forms.content')"
    class="mt-8 content-form"
  >
    <v-card-text>
      <template v-if="!content || !content.length">
        {{ $t('no_content') }}
      </template>
      <template v-else>
        <div
          v-for="(block, index) in content"
          :key="`content-block-${index}`"
          class="content-form__block"
        >
          <div class="content-form__block--actions">
            <div
              v-if="index > 0"
              class="content-form__block--action"
              @click="moveUp(index)"
            >
              <i class="fas fa-caret-up"></i>
            </div>
            <div
              class="content-form__block--action"
              @click="deleteBlock(index)"
            >
              <i class="fas fa-trash"></i>
            </div>
            <div
              v-if="index < (content.length - 1)"
              class="content-form__block--action"
              @click="moveDown(index)"
            >
              <i class="fas fa-caret-down"></i>
            </div>
          </div>
          <component
            :is="block.type"
            v-model="content[index].content"
            class="content-form__block--content"
          />
        </div>
      </template>
      <slot></slot>
    </v-card-text>
  </material-card>
</template>

<script>
export default {
  name: 'ContentForm',

  components: {
    BlockText: () => import(
      /* webpackChunkName: "content-block-text" */
      './ContentText'
      ),
    BlockImage: () => import(
      /* webpackChunkName: "content-block-image" */
      './ContentImage'
      ),
    BlockVideo: () => import(
      /* webpackChunkName: "content-block-video" */
      './ContentVideo'
      ),
    BlockFacebookEmbed: () => import(
      /* webpackChunkName: "content-block-facebook-embed" */
      './ContentFacebookEmbed'
      ),
    BlockTwitterPost: () => import(
      /* webpackChunkName: "content-block-twitter-post" */
      './ContentTwitterPost'
      ),
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['value'],

  data: () => ({
    content: [],
  }),

  watch: {
    value() {
      this.content = this.value;
    },
  },

  created() {
    this.content = this.value;
  },

  methods: {
    arrayMove(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
    moveUp(index) {
      if (index > 0) {
        this.arrayMove(this.content, index, index - 1);
      }
    },
    moveDown(index) {
      if (index < (this.content.length - 1)) {
        this.arrayMove(this.content, index, index + 1)
      }
    },
    deleteBlock(index) {
      this.content.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.content-form {
  &__block {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;

    &--action {
      border-left: 1px solid #ececec;
      border-top: 1px solid #ececec;
      border-bottom: 1px solid #ececec;
      padding: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    &--content {
      flex: 1;
      border: 1px solid #ececec;

      .tox-tinymce {
        border: none;
      }

      .picture-preview-card {
        width: 100%;
        box-shadow: initial !important;
      }
    }
    .content-video-component {
      padding: 10px;

      .video-preview-card {
        width: 100%;
        box-shadow: initial !important;
      }
    }
  }
}
</style>
