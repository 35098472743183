<template>
  <div class="content-image-component">
    <DropzoneComponent
      v-if="!picture"
      v-model="pictures"
      :label="$t('dropzone.label_singular')"
      :max-files="1"
      no-crop
    />
    <div
      v-else
      class="content-image-component__preview"
    >
      <img
        :src="picture.url"
        class="content-image"
      />
      <v-text-field
        v-model="label"
        outlined
        :label="$t('picture_label')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentImage',

  props: {
    value: {
      type: Object,
    },
  },

  data: () => ({
    pictures: [],
    label: null,
  }),

  computed: {
    picture() {
      if (!this.pictures || !this.pictures[0]) {
        return null;
      }
      return this.pictures[0];
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.pictures = [this.value];
      },
    },
    label() {
      this.$emit('input', { ...this.picture, label: this.label });
    },
    picture: {
      deep: true,
      handler() {
        this.$emit('input', this.picture);
      },
    },
  },

  created() {
    this.pictures = this.value ? [this.value] : [];
  },
};
</script>

<style>
.content-image-component__preview {
  width: 100%;
  padding: 30px;
}

.content-image-component__preview img {
  width: 100%;
}
</style>
