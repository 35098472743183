<template>
  <v-btn
    :color="processing ? 'grey lighten-1' : color"
    rounded
    :type="type"
    @click="submit"
  >
    <template v-if="processing">
      <i class="fas fa-spinner fa-spin"></i>
    </template>
    <template v-else>
      {{ label }}
    </template>
  </v-btn>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'SubmitButton',

    props: {
      label: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        default: 'primary',
      },
      type: {
        type: String,
        default: 'button',
      },
    },

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
      }),
    },

    methods: {
      submit () {
        if (!this.processing) {
          this.$emit('click')
        }
      },
    },
  }
</script>
