import axios from 'axios';

export default {
  namespaced: true,

  state: {
    category: null,
    categories: [],
    eservice: null,
    eservices: [],
  },

  getters: {
    getCategory: state => state.category,
    getCategories: state => state.categories,
    getEservice: state => state.eservice,
    getEservices: state => state.eservices,
  },

  mutations: {
    setCategory: (state, payload) => state.category = payload,
    setCategories: (state, payload) => state.categories = payload,
    setEservice: (state, payload) => state.eservice = payload,
    setEservices: (state, payload) => state.eservices = payload,
  },

  actions: {
    fetchCategory: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/eservice-categories/${id}`)
          .then(response => {
            commit('setCategory', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchCategories: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/eservice-categories')
          .then(response => {
            commit('setCategories', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchEservice: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/eservices/${id}`)
          .then(response => {
            commit('setEservice', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchEservices: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/eservices')
          .then(response => {
            commit('setEservices', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createCategory: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/eservice-categories', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createEservice: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/eservices', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateCategory: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/eservice-categories/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateEservice: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/eservices/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteCategory: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/eservice-categories/${id}`)
          .then(response => {
            commit('setCategory', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteEservice: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/eservices/${id}`)
          .then(response => {
            commit('setEservice', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderCategories: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/eservice-categories/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
    reorderEservices: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/eservices/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
