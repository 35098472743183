<template>
  <v-card class="picture-preview-card mr-3 mb-3">
    <v-card-text>
      <div class="picture-preview-card__picture--container">
        <img
          v-if="isPicture"
          :src="picture.url"
          :alt="picture.label"
          :title="picture.label"
          class="picture-preview-card__picture"
        />
        <i
          v-else
          class="fas fa-file picture-preview-card__file-icon"
        ></i>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-text-field
        v-model="form.label"
        :placeholder="$t('picture_label')"
        @blur="updateLabel"
      >
        <v-icon
          slot="append"
          color="green"
          @click="updateLabel"
        >
          mdi-check
        </v-icon>
      </v-text-field>
    </v-card-actions>
    <i
      v-if="!noMove"
      class="fas fa-arrows-alt grey--text picture-preview-card__move"
    ></i>
    <v-dialog
      v-model="showDeletionConfirmation"
      width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <i
          class="fas fa-trash red--text picture-preview-card__delete"
          v-bind="attrs"
          v-on="on"
        ></i>
      </template>

      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_picture_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="showDeletionConfirmation = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deletePicture"
          >
            {{ $t('delete_picture') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import serverError from '../mixins/serverError';
export default {
  name: 'PicturePreviewCard',

  mixins: [serverError],

  props: {
    picture: {
      type: Object,
      required: true,
    },
    noMove: {
      type: Boolean,
      default: false,
    },
    isPicture: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    form: {
      label: null,
    },
    showDeletionConfirmation: false,
  }),

  created() {
    this.form.label = this.picture.label;
  },

  methods: {
    updateLabel() {
      if (this.form.label) {
        const { label } = this.form;
        const payload = { ...this.picture, label };
        const action = this.isPicture ? 'picture/updatePicture' : 'document/updateDocument';
        const message = this.isPicture ? 'picture_label_updated' : 'document_label_updated';
        this.$store.dispatch(action, payload)
          .then(() => {
            this.$toasted.success(this.$t(message));
            this.$emit('labelupdated', this.form.label);
          })
          .catch(error => this.displayErrors(error));
      }
    },
    deletePicture() {
      /* const action = this.isPicture ? 'picture/deletePicture' : 'document/deleteDocument';
      const message = this.isPicture ? 'picture_deleted' : 'document_deleted';
      this.$store.dispatch(action, this.picture.id)
        .then(() => {
          this.$toasted.success(this.$t(message));
          this.$emit('deleted');
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.showDeletionConfirmation = false); */
      this.$emit('deleted');
    },
  },
};
</script>

<style lang="scss">
.picture-preview-card {
  width: 200px;
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &__file-icon {
    text-align: center;
    font-size: 80px;
  }

  &__delete {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__move {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: grab;
  }

  &__picture, &__file-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--container {
      width: 100%;
      position: relative;
      padding-top: 56.5%;
    }
  }
}
</style>
