<template>
  <div class="custom-dropzone">
    <v-text-field
      v-if="videos.length < maxFiles"
      v-model="form.url"
      :placeholder="$t('video_url')"
    >
      <v-icon
        slot="append"
        color="green"
        @click="addVideo"
      >
        mdi-check
      </v-icon>
    </v-text-field>
    <draggable
      v-if="videos.length"
      v-model="videos"
      tag="div"
      handle=".video-preview-card__move"
      class="custom-dropzone__previews mt-3"
      @change="sortHandler"
    >
      <VideoPreviewCard
        v-for="video in videos"
        :key="`video-preview-item-${video.id}`"
        :video="video"
        no-move
        @deleted="videoDeleted(video)"
      />
    </draggable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../mixins/serverError';
import VideoPreviewCard from './VideoPreviewCard.vue';
export default {
  name: 'VideoAddComponent',

  components: { VideoPreviewCard },

  mixins: [serverError],

  props: {
    unique: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 20,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    form: {
      url: null,
    },
    videos: [],
  }),

  computed: {
    ...mapGetters({
      processing: 'global/getProcessing',
    }),
  },

  watch: {
    value() {
      this.fillVideos();
    },
  },

  mounted() {
    this.fillVideos();
  },

  methods: {
    addVideo() {
      if (!this.processing && this.form.url) {
        const { url } = this.form;
        const payload = { url };
        this.$store.dispatch('video/createVideo', payload)
          .then(response => {
            const payload = [...this.value, response.data.data];
            this.$emit('input', payload);
          }).catch(error => this.displayErrors(error));
      }
    },
    fillVideos() {
      this.videos = [...this.value];
    },
    videoDeleted(video) {
      const payload = this.value.filter(vid => vid.id !== video.id);
      this.$emit('input', payload);
    },
    sortHandler() {
      const order = this.videos.map(video => video.id);
      const payload = { order };
      this.$store.dispatch('video/reorderVideos', payload)
        .then(response => {
          this.videos = response.data.data;
        })
        .catch(() => this.$toasted.error(this.$t('error_happened')));
    },
  },
}
</script>
