<template>
  <div class="custom-dropzone">
    <h4 v-if="fieldLabel">
      {{ fieldLabel }}
    </h4>
    <div
      v-if="!noCrop && maxFiles === 1 && picturesOnly && pictures.length < maxFiles"
      class="dropzone dropzone-with-cropper"
      @click="newPicture"
    >
      <div class="dz-message">
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            Cliquez pour sélectionner votre fichier
          </h3>
          <div class="dropzone-with-cropper__file-input">
            <input
              ref="picture"
              type="file"
              name="picture"
              @change="pictureChangedHandler"
            />
          </div>
        </div>
      </div>
    </div>
    <vue2Dropzone
      v-else-if="pictures.length < maxFiles"
      id="dropzone"
      ref="dropzone"
      :options="dropzoneOptions"
      use-custom-slot
      @vdropzone-success="success"
      @vdropzone-canceled="clear"
      @vdropzone-error="error"
      @vdropzone-queue-complete="queuecomplete"
    >
      <div class="dropzone-custom-content">
        <h3 class="dropzone-custom-title">
          {{ label }}
        </h3>
      </div>
    </vue2Dropzone>
    <draggable
      v-if="pictures.length"
      v-model="pictures"
      tag="div"
      handle=".picture-preview-card__move"
      class="custom-dropzone__previews mt-3"
      @change="sortHandler"
    >
      <PicturePreviewCard
        v-for="picture in pictures"
        :key="`dropzone-preview-item-${picture.id}`"
        :picture="picture"
        :no-move="maxFiles === 1"
        :is-picture="picturesOnly"
        @deleted="pictureDeleted(picture)"
        @labelupdated="labelUpdated(picture, label)"
      />
    </draggable>
    <div
      v-if="showCropper"
      class="dropzone__cropper-modal"
    >
      <div class="dropzone__cropper-modal--inner">
        <PictureCropper
          v-if="temporaryImage"
          :picture="temporaryImage"
          :ratio="ratio"
          @cropped="processCrop"
          @closed="showCropper = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import PicturePreviewCard from './PicturePreviewCard.vue';
import PictureCropper from './PictureCropper.vue';
import axios from 'axios';

export default {
  name: 'DropzoneComponent',

  components: { vue2Dropzone, PicturePreviewCard, PictureCropper },

  props: {
    label: { type: String, default: null },
    unique: { type: Boolean, default: false },
    maxFiles: { type: Number, default: 20 },
    value: { type: Array, default: () => [] },
    fieldLabel: { type: String, default: null },
    picturesOnly: { type: Boolean, default: true },
    ratio: { type: [Number], default: 4 / 3 },
    noCrop: { type: Boolean, default: false },
    isDocuments: { type: Boolean, default: false },
  },

  data: () => ({
    pictures: [],
    showCropper: false,
    temporaryImage: null,
  }),

  computed: {
    uploadUrl() {
      return `${process.env.VUE_APP_SERVER_ENDPOINT}/${this.picturesOnly ? 'pictures' : 'documents'}`;
    },
    uploadHeaders() {
      return {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
    },
    dropzoneOptions() {
      return {
        url: this.uploadUrl,
        thumbnailWidth: 150,
        headers: this.uploadHeaders,
        maxFilesize: 20,
        addRemoveLinks: true,
        maxFiles: this.maxFiles,
        acceptedFiles: this.picturesOnly ? 'image/*' : null,
        dictFileTooBig: this.$t('dropzone.too_big'),
        dictInvalidFileType: this.$t('dropzone.invalid_type'),
        dictResponseError: this.$t('dropzone.error'),
        dictCancelUpload: this.$t('dropzone.cancel'),
        dictUploadCanceled: this.$t('dropzone.canceled'),
        dictCancelUploadConfirmation: this.$t('dropzone.cancel_confirmation'),
        dictRemoveFile: this.$t('dropzone.remove_file'),
        dictRemoveFileConfirmation: this.$t('dropzone.remove_file_confirmation'),
        dictMaxFilesExceeded: this.$t('dropzone.max_files_exceeded'),
        dictFileSizeUnits_TB: this.$t('dropzone.TB'),
        dictFileSizeUnits_GB: this.$t('dropzone.GB'),
        dictFileSizeUnits_MB: this.$t('dropzone.MB'),
        dictFileSizeUnits_KB: this.$t('dropzone.KB'),
        dictFileSizeUnits_b: this.$t('dropzone.b'),
      };
    },
  },

  watch: {
    value() {
      this.fillPictures();
    },
  },

  mounted() {
    this.fillPictures();
  },

  methods: {
    fillPictures() {
      this.pictures = [...this.value];
    },
    success(file, response) {
      console.log('Success File', file, 'response', response.data);
      const { data } = response;
      const payload = [...this.value, data];
      this.$emit('input', payload);
    },
    error(file, message, xhr) {
      console.log('Error File', file, 'MESSAGE', message, 'XHR', xhr);
      if (!xhr) {
        this.$refs.dropzone.removeFile(file);
      }
      this.$toasted.error(message);
    },
    clear() {
      this.$refs.dropzone.removeAllFiles();
      this.$emit('input', null);
    },
    queuecomplete(event) {
      console.log('Queue complete');
      this.$refs.dropzone.removeAllFiles();
    },
    pictureDeleted(picture) {
      const payload = this.value.filter(pic => pic.id !== picture.id);
      this.$emit('input', payload);
    },
    sortHandler() {
      const order = this.pictures.map(picture => picture.id);
      const payload = { order };
      const action = this.isDocuments ? 'document/reorderDocuments' : 'picture/reorderPictures';
      this.$store.dispatch(action, payload)
        .then(response => {
          this.pictures = response.data.data;
        })
        .catch(() => this.$toasted.error(this.$t('error_happened')));
    },
    labelUpdated(picture, label) {
      const newPictures = this.value.reduce((acc, item) => {
        acc.push(item.id === picture.id ? {
          ...item,
          label,
        } : item)
        return acc;
      }, []);
      this.$emit('input', newPictures);
    },
    pictureChangedHandler(e) {
      this.temporaryImage = null;
      this.showCropper = true;
      const file = e.target.files[0];
      this.toBase64(file).then(result => this.temporaryImage = result);
    },
    newPicture() {
      this.$refs.picture.click();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    processCrop(croppedPicture) {
      console.log('CROPPED', croppedPicture);
      const formData = new FormData()
      formData.append('file', croppedPicture);
      axios.post(this.uploadUrl, formData, {
        ...this.uploadHeaders,
        'Content-Type': 'multipart/form-data',
      }).then((response) => {
        const { data } = response.data;
        const payload = [...this.value, data];
        this.$emit('input', payload);
        this.temporaryImage = null;
        this.showCropper = false;
      });
    },
  },
}
</script>

<style lang="scss">
@import '~vue2-dropzone/dist/vue2Dropzone.min.css';
.vue-dropzone>.dz-preview .dz-remove {
  margin: 0 15px;
}

.custom-dropzone {
  &__previews {
    display: flex;
    flex-wrap: wrap;
  }
}

.dropzone {
  &__cropper-modal {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(#000000, .7);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &--inner {
      width: 80vw;
      height: 80vh;
    }
  }

  &-with-cropper {
    cursor: pointer;

    &__file-input {
      display: none;
    }
  }
}
</style>
