<template>
  <v-card class="video-preview-card mr-3 mb-3">
    <v-card-text>
      <div class="video-preview-card__video--container">
        <div v-if="video.type === 'local'">
          {{ $t('coming_soon') }}
        </div><!-- TODO : handle local videos embed -->
        <iframe
          v-else
          :src="video.embed_url"
          class="video-preview-card__player"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </v-card-text>
    <i
      v-if="!noMove"
      class="fas fa-arrows-alt grey--text video-preview-card__move"
    ></i>
    <!--<v-dialog
      v-model="showDeletionConfirmation"
      width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <i
          class="fas fa-trash red--text video-preview-card__delete"
          v-bind="attrs"
          v-on="on"
        ></i>
      </template>

      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_video_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="showDeletionConfirmation = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteVideo"
          >
            {{ $t('delete_video') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </v-card>
</template>

<script>
import serverError from '../mixins/serverError';
export default {
  name: 'VideoPreviewCard',

  mixins: [serverError],

  props: {
    video: {
      type: Object,
      required: true,
    },
    noMove: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    form: {
      label: null,
    },
    showDeletionConfirmation: false,
  }),

  created() {
    this.form.label = this.video.label;
  },

  methods: {
    deleteVideo() {
      this.$store.dispatch('video/deleteVideo', this.video.id)
        .then(() => {
          this.$toasted.success(this.$t('video_deleted'));
          this.$emit('deleted');
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.showDeletionConfirmation = false);
    },
  },
};
</script>

<style lang="scss">
.video-preview-card {
  width: 200px;
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &__delete {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__move {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: grab;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--container {
      width: 100%;
      position: relative;
      padding-top: 56.5%;
    }
  }

  iframe {
    width: 95%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
