<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="form.date"
        :label="$t('forms.new_date')"
        prepend-icon="mdi-calendar"
        readonly
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          slot="append"
          color="green"
          @click="addDate"
        >
          mdi-check
        </v-icon>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="form.date"
      :locale="locale"
      :first-day-of-week="1"
      @input="showDatePicker = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../mixins/serverError';
export default {
  name: 'EventDateFormComponent',

  mixins: [serverError],

  data: () => ({
    form: {
      date: null,
    },
    showDatePicker: false,
  }),

  computed: {
    ...mapGetters({
      processing: 'global/getProcessing',
    }),
    locale() {
      switch (process.env.VUE_APP_LOCALE) {
        case 'fr':
          return 'fr-FR';
        case 'en':
          return 'en-US';
        default:
          return 'en-US';
      }
    },
  },

  methods: {
    addDate() {
      if (this.form.date && !this.processing) {
        const payload = {
          date: this.form.date,
        };
        this.$store.dispatch('event/createDate', payload)
          .then(response => {
            this.$emit('added', response.data.data);
            this.form.date = null;
            this.$toasted.success(this.$t('successfully_created'));
          })
          .catch(error => this.displayErrors(error));
      }
    },
  },
};
</script>
