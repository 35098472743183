<template>
  <div class="content-block-toolbar">
    <h4>Ajout contenu</h4>
    <div class="content-block-toolbar__icons">
      <div
        v-for="block in blocks"
        :key="`content-toolbar-${block.id}`"
        class="content-block-toolbar__icon"
        @click="handleBlockClick(block)"
      >
        <div class="content-block-toolbar__icon--label">
          {{ block.label }}
        </div>
        <i
          class="content-block-toolbar__icon--icon"
          :class="block.icon"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentBlockToolbar',

  data: () => ({}),

  computed: {
    blocks() {
      return [
        {
          id: 1,
          label: this.$t('blocks.text'),
          icon: 'fas fa-align-left',
          component: 'BlockText',
        },
        {
          id: 2,
          label: this.$t('blocks.image'),
          icon: 'far fa-image',
          component: 'BlockImage',
        },
        {
          id: 3,
          label: this.$t('blocks.video'),
          icon: 'fab fa-youtube',
          component: 'BlockVideo',
        },
        {
          id: 4,
          label: this.$t('blocks.facebook'),
          icon: 'fab fa-facebook',
          component: 'BlockFacebookEmbed',
        },
        {
          id: 5,
          label: this.$t('blocks.twitter'),
          icon: 'fab fa-twitter',
          component: 'BlockTwitterPost',
        },
      ];
    },
  },

  methods: {
    handleBlockClick(block) {
      this.$emit('added', block);
    },
  },
};
</script>

<style lang="scss">
.content-block-toolbar {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-left: 1px solid #3C4858;
  border-bottom: 1px solid #3C4858;
  border-top: 1px solid #3C4858;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  max-width: 90px;
  background: rgba(#3C4858, .2);

  &__icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  &__icon {
    cursor: pointer;
    margin-bottom: 10px;
    border: 2px solid #3C4858;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    text-align: center;

    &--label {
      font-weight: bold;
      font-size: 10px;
      margin-bottom: 5px;
    }

    &--icon {
      font-size: 30px;
    }
  }
}
</style>
