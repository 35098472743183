import axios from 'axios';

export default {
  namespaced: true,

  state: {
    document: null,
    documents: [],
  },

  getters: {
    getDocument: state => state.document,
    getDocuments: state => state.documents,
  },

  mutations: {
    setDocument: (state, payload) => state.document = payload,
    setDocuments: (state, payload) => state.documents = payload,
  },

  actions: {
    fetchDocument: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/documents/${id}`)
          .then(response => {
            commit('setDocument', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchDocuments: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        const params = { ...payload };
        axios.get('/documents', { params })
          .then(response => {
            commit('setDocuments', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateDocument: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        const { id } = payload;
        axios.put(`/documents/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteDocument: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/documents/${id}`)
          .then(response => {
            commit('setDocument', null);
            resolve(response);
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderDocuments: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/documents/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
