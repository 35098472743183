<template>
  <div>
    <editor
      v-model="text"
      :api-key="apiKey"
      :init="initSettings"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'WYSISYWEditor',

  components: { Editor },

  props: {
    value: {
      type: String,
      default: null,
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    text: null,
    apiKey: process.env.VUE_APP_TINYMCE_KEY,
  }),

  computed: {
    locale() {
      switch (process.env.VUE_APP_LOCALE) {
        case 'fr':
          return 'fr_FR';
        case 'en':
          return 'en_US';
        default:
          return 'en_US';
      }
    },
    initSettings() {
      return {
        height: 200,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table',
        ],
        toolbar: this.toolbar,
        language: this.locale,
      };
    },
    toolbar() {
      return this.simple
        ? 'undo redo bullist numlist link'
        : 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent link';
    },
  },

  watch: {
    text() {
      this.$emit('input', this.text);
    },
    value() {
      this.text = this.value;
    },
  },

  created() {
    this.text = this.value;
  },
};
</script>

<style lang="scss"></style>
