<template>
  <div class="section-tree-view">
    <DraggableTree
      cross-tree
      :draggable="true"
      :data="treeData"
      @drop="dropped"
    >
      <div
        slot-scope="{ data, store }"
        :class="{ 'selected-node': data.selected && !draggable }"
        style="display: flex; align-items: center;"
        @click="selected(data)"
      >
        <template v-if="!data.isDragPlaceHolder">
          <span
            v-if="data.children && data.children.length"
            style="font-weight: bold; font-size: 20px; margin-right: 10px;"
            @click.prevent.stop="store.toggleOpen(data)"
          >
            <img
              v-if="data.open"
              src="@/assets/caret_down.png"
              class="section-tree-view__caret"
            />
            <img
              v-else
              src="@/assets/caret_up.png"
              class="section-tree-view__caret"
            />
          </span>
          <div style="flex: 1;">{{ data.text }}</div>
          <img
            src="@/assets/sheets.png"
            alt="Réorganiser les pages"
            style="cursor: pointer; height: 20px;"
            @click.prevent.stop="goToPageReorderList(data.id)"
          />
        </template>
      </div>
    </DraggableTree>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DraggableTree } from 'vue-draggable-nested-tree';
export default {
  name: 'SectionTreeViewComponent',

  components: { DraggableTree },

  props: {
    value: {
      type: Number,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    forbidden: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    treeData: [],
  }),

  computed: {
    ...mapGetters({
      sections: 'page/getSectionsTree',
    }),
  },

  created() {
    this.$store.dispatch('page/fetchSectionsTree').then(() => {
      this.fillTreeData();
    });
  },

  methods: {
    filterChildren(children) {
      if (!children) {
        return [];
      }
      return this.forbidden ? children.filter(child => child.id !== this.forbidden) : children;
    },
    fillTreeData(unselect) {
      this.treeData = [
        {
          text: 'Racine du site',
          id: null,
          opened: true,
          draggable: this.draggable,
          children: this.filterChildren(this.sections).map(section => this.formatItem(section, unselect)),
          selected: this.value === null,
        },
      ];
    },
    formatItem(item, unselect) {
      return {
        id: item.id,
        text: item.label,
        draggable: this.draggable,
        selected: !unselect && this.value && this.value === item.id,
        children: this.filterChildren(item.children).map(section => this.formatItem(section, unselect)),
      };
    },
    selected(item) {
      if (this.value === item.id) {
        this.$emit('input', null);
        this.fillTreeData(true);
      } else {
        this.$emit('input', this.value === item.id ? null : item.id);
      }
    },
    async dropped(node, targetTree, oldTree) {
      console.log('NODE', node, 'TARGET TREE', targetTree, 'OLD TREE', oldTree);
      const { id, text, parent } = node;
      console.log('TO move : ', id, text, 'Parent : ' + parent.text + parent.id);
      const newParent = this.findParentNode(targetTree.getPureData()[0], id);
      const payload = {
        parent_id: newParent.id,
        section_id: id,
        order: newParent.children.map(child => child.id),
      };
      this.$store.dispatch('page/dragDropSections', payload).then(() => {
        this.$toasted.success('Rubriques réorganisées');
      });
    },
    findParentNode(parent, id) {
      if (!parent.children) {
        return null;
      }
      const isHere = parent.children.find(node => node.id === id);
      if (isHere) {
        return parent;
      }
      let finalParent = null;
      for (let i = 0; i < parent.children.length; i++) {
        if (this.findParentNode(parent.children[i], id)) {
          finalParent = parent.children[i];
        }
      }
      return finalParent;
    },
    goToPageReorderList(id) {
      this.$router.push(`/pages/sections/${id}/pages`)
    },
  },
};
</script>

<style lang="scss">
/*.section-tree-view {
  .tree-themeicon {
    display: none !important;
  }
  .tree-node {
    margin-left: 0 !important;
  }
}*/

.section-tree-view {
  &__caret {
    height: 12px;
  }
}

.he-tree{
    padding: 20px;
}
.tree-node{
  &-inner{
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    color: #444444;

    &:hover{
      background: #ddd;
    }
  }

  .selected-node {
    background: #2d8c2d;
    color: #ffffff;
  }
}
.draggable-placeholder{
  &-inner{
    box-sizing: border-box;
    background: rgba(0, 136, 249, 0.09);
    color: #0088f9;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
  }
}
</style>
