<template>
  <div class="focus-form">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)">
        <v-card>
          <v-card-title>
            {{ $t(isNew ? 'add_focus' : 'update_focus') }}
          </v-card-title>
          <v-card-text class="pt-4">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="$t('forms.label').toLowerCase()"
            >
              <v-text-field
                v-model="form.label"
                :error-messages="errors"
                outlined
                :label="$t('forms.label')"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="$t('forms.link').toLowerCase()"
            >
              <v-text-field
                v-model="form.link"
                :error-messages="errors"
                outlined
                :label="$t('forms.link')"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="$t('forms.column').toLowerCase()"
            >
              <v-select
                v-model="form.column"
                :items="columns"
                outlined
                :error-messages="errors"
                :label="$t('forms.column')"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules=""
              :name="$t('forms.published_at').toLowerCase()"
            >
              <DateTimePicker
                v-model="form.published_at"
                :label="$t('forms.published_at')"
                with-time
                :errors="errors"
              />
            </validation-provider>
            <DropzoneComponent
              v-model="form.pictures"
              :label="$t('dropzone.label_singular')"
              :max-files="1"
              class="mt-2"
            />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="gray"
              text
              @click="close"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="submit"
            >
              {{ $t('submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../mixins/serverError';
import DateTimePicker from './DateTimePicker';
export default {
  name: 'FocusFormComponent',

  components: { DateTimePicker },

  mixins: [serverError],

  props: {
    focus: {
      type: Object,
      default: null,
    },
    column: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    form: {
      label: null,
      link: null,
      pictures: [],
      column: null,
      published_at: null,
    },
    columns: [1, 2, 3, 4],
  }),

  computed: {
    ...mapGetters({
      processing: 'global/getProcessing',
    }),
    isNew() {
      return this.form.id;
    },
  },

  watch: {
    focus: {
      deep: true,
      handler() {
        this.fillData();
      },
    },
  },

  created() {
    this.fillData();
  },

  methods: {
    fillData() {
      this.form.column = this.column;
      if (this.focus) {
        this.form = {
          ...this.focus,
          pictures: [this.focus.picture],
        };
      } else {
        this.form = {
          label: null,
          link: null,
          pictures: [],
          column: this.column,
          published_at: null,
        }
      }
    },
    close() {
      this.$emit('closed');
    },
    submit() {
      if (!this.processing) {
        const action = this.isNew ? 'focus/updateFocus' : 'focus/createFocus';
        const message = this.$t(this.isNew ? 'successfully_created' : 'successfully_updated');
        const { id, label, pictures, column, published_at, link } = this.form;
        const picture_id = pictures[0] && pictures[0].id;
        if (picture_id) {
          const payload = { id, label, picture_id, column, published_at, link };
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$emit(this.isNew ? 'updated' : 'created');
            })
            .catch(error => this.displayErrors(error))
            .finally(() => this.close());
        }
      }
    },
  },
};
</script>

<style lang="scss">
.focus-form {}
</style>
