import axios from 'axios';

export default {
  namespaced: true,

  state: {
    elu: null,
    elus: [],
  },

  getters: {
    getElu: state => state.elu,
    getElus: state => state.elus,
  },

  mutations: {
    setElu: (state, payload) => state.elu = payload,
    setElus: (state, payload) => state.elus = payload,
  },

  actions: {
    fetchElu: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/elus/${id}`)
          .then(response => {
            commit('setElu', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchElus: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/elus')
          .then(response => {
            commit('setElus', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createElu: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/elus', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateElu: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/elus/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteElu: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/elus/${id}`)
          .then(response => {
            commit('setElu', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderElus: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/elus/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
