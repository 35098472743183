// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: false,
  mini: false,
  items: [
    {
      title: 'dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'pages',
      icon: 'fas fa-laptop',
      items: [
        { title: 'sections', to: '/pages/sections' },
        { title: 'pages', to: '/pages/pages' },
      ],
    },
    {
      title: 'homepage',
      icon: 'fas fa-home',
      items: [
        { title: 'quick_access', to: '/quick-links/quick-links' },
        { title: 'focus', to: '/focus' },
        { title: 'sites', to: '/sites' },
      ],
    },
    {
      title: 'news',
      icon: 'far fa-newspaper',
      items: [
        { title: 'categories', to: '/news/categories' },
        { title: 'articles', to: '/news/articles' },
      ],
    },
    {
      title: 'agenda',
      icon: 'far fa-calendar-alt',
      items: [
        { title: 'categories', to: '/events/categories' },
        { title: 'events', to: '/events/events' },
      ],
    },
    {
      title: 'elus',
      icon: 'fas fa-user-tie',
      to: '/elus',
    },
    {
      title: 'eservices',
      icon: 'fas fa-wifi',
      items: [
        { title: 'categories', to: '/eservices/categories' },
        { title: 'eservices', to: '/eservices/eservices' },
      ],
    },
    {
      title: 'contacts',
      icon: 'far fa-address-book',
      to: '/contacts',
    },
    {
      title: 'accounts',
      icon: 'fas fa-users',
      to: '/accounts',
    },
    /* {
      title: 'Pages',
      icon: 'mdi-image',
      items: [
        {
          title: 'Pricing',
          to: '/pages/pricing/',
        },
        {
          title: 'RTL Support',
          to: '/pages/rtl/',
        },
        {
          title: 'Timeline',
          to: '/components/timeline/',
        },
        {
          title: 'Login Page',
          to: '/pages/login/',
        },
        {
          title: 'Register Page',
          to: '/pages/register/',
        },
        {
          title: 'Lock Screen Page',
          to: '/pages/lock/',
        },
        {
          title: 'User Profile',
          to: '/components/profile/',
        },
        {
          title: 'Error Page',
          to: '/pages/error/',
        },
      ],
    },
    {
      title: 'Components',
      icon: 'mdi-view-comfy',
      items: [
        {
          title: 'Multi Level Collapse',
          items: [{
            title: 'Example',
          }],
        },
        {
          title: 'Buttons',
          to: '/components/buttons/',
        },
        {
          title: 'Grid System',
          to: '/components/grid/',
        },
        {
          title: 'Tabs',
          to: '/components/tabs/',
        },
        {
          title: 'Notifications',
          to: '/components/notifications/',
        },
        {
          title: 'Icons',
          to: '/components/icons/',
        },
        {
          title: 'Typography',
          to: '/components/typography/',
        },
      ],
    },
    {
      title: 'Forms',
      icon: 'mdi-clipboard-outline',
      items: [
        {
          title: 'Regular Forms',
          to: '/forms/regular/',
        },
        {
          title: 'Extended Forms',
          to: '/forms/extended/',
        },
        {
          title: 'Valiation Forms',
          to: '/forms/validation/',
        },
        {
          title: 'Wizard',
          to: '/forms/wizard/',
        },
      ],
    },
    {
      title: 'Tables',
      icon: 'mdi-grid',
      items: [
        {
          title: 'Regular Tables',
          to: '/tables/regular/',
        },
        {
          title: 'Extended Tables',
          to: '/tables/extended/',
        },
        {
          title: 'DataTables Tables',
          to: '/tables/data-tables/',
        },
      ],
    },
    {
      title: 'Maps',
      icon: 'mdi-map-marker',
      items: [
        {
          title: 'Google Maps',
          to: '/maps/google/',
        },
        {
          title: 'Fullscreen Map',
          to: '/maps/fullscreen/',
        },
      ],
    },
    {
      title: 'Widgets',
      icon: 'mdi-widgets',
      to: '/widgets/',
    },
    {
      title: 'Charts',
      icon: 'mdi-chart-timeline-variant',
      to: '/charts/',
    },
    {
      title: 'Calendar',
      icon: 'mdi-calendar-range-outline',
      to: '/calendar/',
    },

     */
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {
  accountCreationEnabled: () => process.env.VUE_APP_ACCOUNT_CREATION_ENABLED === '1',
  socialLoginEnabled: () => process.env.VUE_APP_SOCIAL_LOGIN_ENABLED === '1',
  appName: () => process.env.VUE_APP_NAME,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
