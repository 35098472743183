var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"focus-form"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t(_vm.isNew ? 'add_focus' : 'update_focus'))+" ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.label').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.label')},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.link').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.link')},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.column').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.columns,"outlined":"","error-messages":errors,"label":_vm.$t('forms.column')},model:{value:(_vm.form.column),callback:function ($$v) {_vm.$set(_vm.form, "column", $$v)},expression:"form.column"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.published_at').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTimePicker',{attrs:{"label":_vm.$t('forms.published_at'),"with-time":"","errors":errors},model:{value:(_vm.form.published_at),callback:function ($$v) {_vm.$set(_vm.form, "published_at", $$v)},expression:"form.published_at"}})]}}],null,true)}),_c('DropzoneComponent',{staticClass:"mt-2",attrs:{"label":_vm.$t('dropzone.label_singular'),"max-files":1},model:{value:(_vm.form.pictures),callback:function ($$v) {_vm.$set(_vm.form, "pictures", $$v)},expression:"form.pictures"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }