import { render, staticRenderFns } from "./MaterialRevealCard.vue?vue&type=template&id=2362f672&"
import script from "./MaterialRevealCard.vue?vue&type=script&lang=js&"
export * from "./MaterialRevealCard.vue?vue&type=script&lang=js&"
import style0 from "./MaterialRevealCard.vue?vue&type=style&index=0&id=2362f672&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VCardText,VHover})
