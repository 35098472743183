<template>
  <div
    class="color-preview"
    :style="{ background: color }"
  ></div>
</template>

<script>
export default {
  name: 'ColorPreview',

  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.color-preview {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  content: '';
}
</style>
