import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messagesFr from '@/i18n/messages/fr'
import messagesEn from '@/i18n/messages/en'
import fr from 'vuetify/lib/locale/fr'
import en from 'vuetify/lib/locale/en'
import { extend } from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  required,
  confirmed,
} from 'vee-validate/dist/rules'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    fr: {
      ...messagesFr,
      $vuetify: fr,
    },
    en: {
      ...messagesEn,
      $vuetify: en,
    },
  },
})

extend('email', {
  ...email,
  message: (_, values) => i18n.t('form.email', values),
})
extend('max', {
  ...max,
  message: (_, values) => i18n.t('form.max', values),
})
extend('min', {
  ...min,
  message: (_, values) => i18n.t('form.min', values),
})
extend('numeric', {
  ...numeric,
  message: (_, values) => i18n.t('form.numeric', values),
})
extend('required', {
  ...required,
  message: (_, values) => i18n.t('form.required', values),
})
extend('confirmed', {
  ...confirmed,
  message: (_, values) => i18n.t('form.confirmed', values),
})

export default i18n
