<template>
  <div class="date-time-picker">
    <!--<datetime-picker
      v-if="withTime"
      v-model="date"
      time-type="minute"
      :placeholder="label"
      :first-day-of-week="1"
      :btn-str="$t('submit')"
      :day-str="dayStr"
      :month-str="monthStr"
      :time-str="timeStr"
      :scrollbar-props="{}"
      :popper-props="{
        placement: 'bottom-start',
        positionFixed: true,
        // more options in https://popper.js.org
      }"
      @input="dateTimePickerHandler"
    />
    <datepicker
      v-else
      v-model="date"
      type="date"
      :placeholder="label"
      :first-day-of-week="1"
      :btn-str="$t('submit')"
      :day-str="dayStr"
      :month-str="monthStr"
      :scrollbar-props="{}"
      :popper-props="{
        placement: 'bottom-start',
        positionFixed: true,
        // more options in https://popper.js.org
      }"
      @input="datePickerHandler"
    />-->
    <vc-date-picker
      v-model="date"
      :popover="popover"
      :mode="withTime ? 'dateTime' : 'date'"
      is24hr
      :minute-increment="5"
      :model-config="modelConfig"
      locale="fr"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <v-text-field
          :value="inputValue"
          :label="label"
          outlined
          v-on="inputEvents"
        />
      </template>
    </vc-date-picker>
  </div>
</template>

<script>
export default {
  name: 'DateTimePickerComponent',

  props: {
    value: {
      type: String,
      default: null,
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    date: null,
    dayStr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthStr: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    timeStr: ['Heure', 'Min.', 'Sec.'],
    popover: {
      placement: 'bottom',
      visibility: 'click',
    },
  }),

  computed: {
    modelConfig() {
      return {
        type: 'string',
        mask: this.withTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD',
      };
    },
  },

  watch: {
    value() {
      this.date = this.value;
    },
    date() {
      this.datePickerHandler(this.date);
    },
  },

  created() {
    if (this.value) {
      this.date = this.value;
    }
  },

  methods: {
    dateTimePickerHandler(datetime) {
      this.$emit('input', datetime);
    },
    datePickerHandler(date) {
      this.$emit('input', date);
    },
  },
};
</script>

<style lang="scss">
.date-time-picker {
  .datetime-picker, .datepicker {
    .input-wrapper {
      border-color: currentColor;
      font-size: 16px;

      input {
        min-height: 56px;
        border-radius: 4px;
        padding: 0 12px;
        color: #333333;
        border-color: currentColor;
      }
    }
  }
}
</style>
