<template>
  <div class="event-category-picker">
    <div class="event-category-picker__actions text-right">
      <v-icon
        @click="initiateCategoryAdd"
      >
        fas fa-plus
      </v-icon>
    </div>
    <v-select
      v-model="category_id"
      :items="categories"
      item-text="label"
      item-value="id"
      outlined
      :error-messages="errors"
      :label="$t('forms.category')"
    />
    <v-dialog
      v-model="showAddDialog"
      width="300"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_category') }}
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="category_label"
            :placeholder="$t('forms.label')"
          >
          </v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeAddDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="addCategory"
          >
            {{ $t('add_category') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'EventCategoryPicker',

  props: {
    value: {
      type: Number,
      default: null,
    },
    errors: {
      type: [Array, Object],
      default: null,
    },
  },

  data: () => ({
    category_id: null,
    showAddDialog: false,
    category_label: null,
  }),

  computed: {
    ...mapGetters({
      categories: 'event/getCategories',
      processing: 'global/getProcessing',
    }),
  },

  watch: {
    category_id() {
      this.$emit('input', this.category_id);
    },
    value() {
      this.category_id = this.value;
    },
  },

  created() {
    this.$store.dispatch('event/fetchCategories');
    this.category_id = this.value;
  },

  methods: {
    refreshData() {
      this.$store.dispatch('event/fetchCategories');
    },
    initiateCategoryAdd(elu) {
      this.category_label = null;
      this.showAddDialog = true;
    },
    closeAddDialog() {
      this.category_label = null;
      this.showAddDialog = false;
    },
    addCategory() {
      if (this.category_label && !this.processing) {
        this.$store.dispatch('event/createCategory', { label: this.category_label })
          .then(response => {
            this.$toasted.success(this.$t('category_added'));
            this.category_id = response.data.data.id;
            this.refreshData();
          })
          .catch(error => this.displayErrors(error))
          .finally(() => this.closeAddDialog());
      }
    },
  },
};
</script>

<style lang="scss">
.event-category-picker {
  &__actions {
    .v-icon.v-icon.v-icon--link {
      font-size: 15px;
    }
  }
}
</style>
