import axios from 'axios';

export default {
  namespaced: true,

  state: {
    category: null,
    categories: [],
    article: null,
    articles: [],
    articles_meta: {},
    related_articles: [],
  },

  getters: {
    getCategory: state => state.category,
    getCategories: state => state.categories,
    getArticle: state => state.article,
    getArticles: state => state.articles,
    getArticlesMeta: state => state.articles_meta,
    getRelatedArticles: state => state.related_articles,
  },

  mutations: {
    setCategory: (state, payload) => state.category = payload,
    setCategories: (state, payload) => state.categories = payload,
    setArticle: (state, payload) => state.article = payload,
    setArticles: (state, payload) => state.articles = payload,
    setArticlesMeta: (state, payload) => state.articles_meta = payload,
    setRelatedArticles: (state, payload) => state.related_articles = payload,
  },

  actions: {
    fetchCategory: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/categories/${id}`)
          .then(response => {
            commit('setCategory', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchCategories: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/categories')
          .then(response => {
            commit('setCategories', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchArticle: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/articles/${id}`)
          .then(response => {
            commit('setArticle', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchArticles: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/articles', payload)
          .then(response => {
            commit('setArticles', response.data.data)
            commit('setArticlesMeta', response.data.meta)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchRelatedArticles: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/articles/related/${id}`)
          .then(response => {
            commit('setRelatedArticles', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createCategory: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/categories', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createContent: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/contents', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createArticle: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/articles', payload)
          .then(response => {
            const contentPayload = {
              contentable_id: response.data.data.id,
              contentable_type: 'App\\Models\\Article',
              content: payload.content,
            }
            dispatch('createContent', contentPayload)
              .then(response => resolve(response))
              .catch(error => reject(error));
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateCategory: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/categories/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateArticle: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/articles/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteCategory: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/categories/${id}`)
          .then(response => {
            commit('setCategory', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteArticle: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/articles/${id}`)
          .then(response => {
            commit('setArticle', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderCategories: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/categories/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
