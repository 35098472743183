import axios from 'axios';

export default {
  namespaced: true,

  state: {
    site: null,
    sites: [],
  },

  getters: {
    getSite: state => state.site,
    getSites: state => state.sites,
  },

  mutations: {
    setSite: (state, payload) => state.site = payload,
    setSites: (state, payload) => state.sites = payload,
  },

  actions: {
    fetchSite: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/sites/${ id }`)
          .then(response => {
            commit('setSite', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchSites: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/sites')
          .then(response => {
            commit('setSites', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createSite: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/sites', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateSite: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/sites/${ id }`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteSite: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/sites/${ id }`)
          .then(response => {
            commit('setSite', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderSites: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/sites/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
