import axios from 'axios';

export default {
  namespaced: true,

  state: {
    picture: null,
    pictures: [],
  },

  getters: {
    getPicture: state => state.picture,
    getPictures: state => state.pictures,
  },

  mutations: {
    setPicture: (state, payload) => state.picture = payload,
    setPictures: (state, payload) => state.pictures = payload,
  },

  actions: {
    fetchPicture: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/pictures/${id}`)
          .then(response => {
            commit('setPicture', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchPictures: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        const params = { ...payload };
        axios.get('/pictures', { params })
          .then(response => {
            commit('setPictures', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updatePicture: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        const { id } = payload;
        axios.put(`/pictures/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deletePicture: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/pictures/${id}`)
          .then(response => {
            commit('setPicture', null);
            resolve(response);
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderPictures: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/pictures/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
