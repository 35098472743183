<template>
  <div class="content-facebook-embed-component">
    <v-text-field
      v-model="text"
      outlined
      :label="$t('forms.facebook_embed_code')"
    />
    <template v-if="text">
      <h4>{{ $t('preview') }}</h4>
      <div v-html="text"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ContentFacebookEmbed',

  props: {
    value: {
      type: String,
    },
  },

  data: () => ({
    text: null,
  }),

  watch: {
    value: {
      deep: true,
      handler() {
        this.text = this.value;
      },
    },
    text() {
      this.$emit('input', this.text);
    },
  },

  created() {
    this.text = this.value;
  },
};
</script>
