import axios from 'axios';

export default {
  namespaced: true,

  state: {
    contact: null,
    contacts: [],
  },

  getters: {
    getContact: state => state.contact,
    getContacts: state => state.contacts,
  },

  mutations: {
    setContact: (state, payload) => state.contact = payload,
    setContacts: (state, payload) => state.contacts = payload,
  },

  actions: {
    fetchContact: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/contacts/${ id }`)
          .then(response => {
            commit('setContact', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchContacts: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/contacts')
          .then(response => {
            commit('setContacts', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createContact: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/contacts', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateContact: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/contacts/${ id }`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteContact: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/contacts/${ id }`)
          .then(response => {
            commit('setContact', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderContacts: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/contacts/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
