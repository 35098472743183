import Vue from 'vue';
import { Datepicker, Timepicker, DatetimePicker, DateRangePicker } from '@livelybone/vue-datepicker';
import '@livelybone/vue-datepicker/lib/css/index.css';
import VCalendar from 'v-calendar';

Vue.use(VCalendar, { componentPrefix: 'vc' });

// Global register
Vue.component('datepicker', Datepicker);
Vue.component('timepicker', Timepicker);
Vue.component('datetime-picker', DatetimePicker);
Vue.component('date-range-picker', DateRangePicker);
