export default {
  methods: {
    displayErrors (error) {
      if (error.response.status === 404) {
        this.$toasted.error(this.$t('error_happened'));
      } else {
        const errorList = error.response.data.errors;
        if (errorList && Object.keys(errorList) && Object.keys(errorList).length) {
          Object.keys(errorList).map(key => {
            if (errorList[key] && errorList[key].length) {
              errorList[key].map(message => {
                this.$toasted.error(message);
              });
            }
          });
        }
      }
    },
  },
};
