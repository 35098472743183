<template>
  <div class="content-twitter-post">
    <v-text-field
      v-model="text"
      outlined
      :label="$t('forms.twitter_link')"
    />
    <template v-if="twitterId">
      <h4>{{ $t('preview') }}</h4>
      <Tweet :id="twitterId" />
    </template>
  </div>
</template>

<script>
import { Tweet } from 'vue-tweet-embed'
export default {
  name: 'ContentTwitterPost',

  components: { Tweet },

  props: {
    value: {
      type: String,
    },
  },

  data: () => ({
    text: null,
  }),

  computed: {
    twitterId() {
      if (!this.text) {
        return null;
      }
      const url = this.text.split('?')[0];
      const urlSections = url.split('/');
      const id = urlSections[urlSections.length - 1];
      return id || null;
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.text = this.value;
      },
    },
    text() {
      this.$emit('input', this.text);
    },
  },

  created() {
    this.text = this.value;
  },
};
</script>
