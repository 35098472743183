import axios from 'axios';

export default {
  namespaced: true,

  state: {
    category: null,
    categories: [],
    event: null,
    events: [],
    eventsMeta: {
      current_page: 1,
      last_page: 1,
      total: 0,
    },
  },

  getters: {
    getCategory: state => state.category,
    getCategories: state => state.categories,
    getEvent: state => state.event,
    getEvents: state => state.events,
    getEventsMeta: state => state.eventsMeta,
  },

  mutations: {
    setCategory: (state, payload) => state.category = payload,
    setCategories: (state, payload) => state.categories = payload,
    setEvent: (state, payload) => state.event = payload,
    setEvents: (state, payload) => state.events = payload,
    setEventsMeta: (state, payload) => state.eventsMeta = payload,
  },

  actions: {
    fetchCategory: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/event-categories/${ id }`)
          .then(response => {
            commit('setCategory', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchCategories: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/event-categories')
          .then(response => {
            commit('setCategories', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchEvent: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/events/${ id }`)
          .then(response => {
            commit('setEvent', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchEvents: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get('/events', payload)
          .then(response => {
            commit('setEvents', response.data.data)
            commit('setEventsMeta', response.data.meta)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createCategory: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/event-categories', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createEvent: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/events', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createDate: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/event-dates', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateCategory: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/event-categories/${ id }`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateEvent: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        commit('global/setProcessing', true, { root: true })
        axios.put(`/events/${ id }`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteCategory: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/event-categories/${ id }`)
          .then(response => {
            commit('setCategory', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteEvent: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/events/${ id }`)
          .then(response => {
            commit('setEvent', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    duplicateEvent: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post(`/events/${ id }/duplicate`)
          .then(response => {
            commit('setEvent', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteDate: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/event-dates/${ id }`)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderCategories: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/event-categories/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
