import Vue from 'vue';
import Toasted from 'vue-toasted';
import 'vue-toasted/dist/vue-toasted.min.css';

Vue.use(Toasted, {
  duration: 5000,
  iconPack: 'fontawesome',
  theme: 'toasted-primary',
  position: 'bottom-right',
});
