<template>
  <div class="custom-color-picker">
    <h4 v-if="label">
      {{ label }}
    </h4>
    <v-color-picker
      v-model="color"
      dot-size="21"
      hide-mode-switch
      mode="hexa"
    ></v-color-picker>
    <div
      v-if="errorMessages.length"
      class="v-text-field__details"
    >
      <div
        class="v-messages theme--light error--text"
        role="alert"
      >
        <div class="v-messages__wrapper">
          <div
            v-for="(message, index) in errorMessages"
            :key="`color-picker-error-${index}`"
            class="v-messages__message"
          >
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',

  props: {
    value: {
      type: [String, Object, null],
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: [Array, String, Object, null],
      default: () => [],
    },
  },

  data: () => ({
    color: null,
  }),

  watch: {
    color() {
      this.$emit('input', this.color);
    },
    value() {
      this.color = this.value;
    },
  },

  created() {
    this.color = this.value;
  },
};
</script>

<style lang="scss">
.custom-color-picker {}
</style>
