import axios from 'axios';

export default {
  namespaced: true,

  state: {
    video: null,
    videos: [],
  },

  getters: {
    getVideo: state => state.video,
    getVideos: state => state.videos,
  },

  mutations: {
    setVideo: (state, payload) => state.video = payload,
    setVideos: (state, payload) => state.videos = payload,
  },

  actions: {
    fetchVideo: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.get(`/videos/${id}`)
          .then(response => {
            commit('setVideo', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    fetchVideos: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        const params = { ...payload };
        axios.get('/videos', { params })
          .then(response => {
            commit('setVideos', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    createVideo: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/videos', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    updateVideo: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        const { id } = payload;
        axios.put(`/videos/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    deleteVideo: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.delete(`/videos/${id}`)
          .then(response => {
            commit('setVideo', null);
            resolve(response);
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    reorderVideos: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/videos/reorder', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
};
