// Imports
import Vue from 'vue'
import Router from 'vue-router'
// import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      route('Dashboard'),
      route('UserProfile', null, 'profile'),

      // E-services
      route('Eservices/CategoriesList', null, 'eservices/categories'),
      route('Eservices/CategoryForm', null, 'eservices/categories/new'),
      route('Eservices/CategoryForm', null, 'eservices/categories/:id'),
      route('Eservices/EservicesList', null, 'eservices/eservices'),
      route('Eservices/EserviceForm', null, 'eservices/eservices/new'),
      route('Eservices/EserviceForm', null, 'eservices/eservices/:id'),

      // Élus
      route('Elus/ElusList', null, 'elus'),
      route('Elus/EluForm', null, 'elus/new'),
      route('Elus/EluForm', null, 'elus/:id'),

      // Actualités
      route('News/CategoriesList', null, 'news/categories'),
      route('News/CategoryForm', null, 'news/categories/new'),
      route('News/CategoryForm', null, 'news/categories/:id'),
      route('News/ArticlesList', null, 'news/articles'),
      route('News/ArticleForm', null, 'news/articles/new'),
      route('News/ArticleForm', null, 'news/articles/:id'),

      // Pages
      route('Page/SectionsList', null, 'pages/sections'),
      route('Page/SectionForm', null, 'pages/sections/new'),
      route('Page/SectionPageList', null, 'pages/sections/:id/pages'),
      route('Page/SectionForm', null, 'pages/sections/:id'),
      route('Page/PagesList', null, 'pages/pages'),
      route('Page/PageForm', null, 'pages/pages/new'),
      route('Page/PageForm', null, 'pages/pages/:id'),

      // Quick Links
      route('QuickLink/QuickLinkList', null, 'quick-links/quick-links'),
      route('QuickLink/QuickLinkForm', null, 'quick-links/quick-links/new'),
      route('QuickLink/QuickLinkForm', null, 'quick-links/quick-links/:id'),

      // Events
      route('Events/CategoriesList', null, 'events/categories'),
      route('Events/CategoryForm', null, 'events/categories/new'),
      route('Events/CategoryForm', null, 'events/categories/:id'),
      route('Events/EventsList', null, 'events/events'),
      route('Events/EventForm', null, 'events/events/new'),
      route('Events/EventForm', null, 'events/events/:id'),

      // Accounts
      route('Accounts/AccountsList', null, 'accounts'),
      route('Accounts/AccountForm', null, 'accounts/new'),
      route('Accounts/AccountForm', null, 'accounts/:id'),

      // Homepage
      route('Homepage/SectionsList', null, 'homepage/quick-access'),

      // Contacts
      route('Contacts/ContactsList', null, 'contacts'),
      route('Contacts/ContactForm', null, 'contacts/new'),
      route('Contacts/ContactForm', null, 'contacts/:id'),

      // External sites
      route('Sites/SitesList', null, 'sites'),
      route('Sites/SiteForm', null, 'sites/new'),
      route('Sites/SiteForm', null, 'sites/:id'),

      // Focus
      route('Focus', null, '/focus'),

      route('Timeline', null, 'components/timeline'),
      route('UserProfile', null, 'components/profile'),

      // Components
      route('Buttons', null, 'components/buttons'),
      route('Grid', null, 'components/grid'),
      route('Tabs', null, 'components/tabs'),
      route('Notifications', null, 'components/notifications'),
      route('Icons', null, 'components/icons'),
      route('Typography', null, 'components/typography'),

      // Forms
      route('Regular Forms', null, 'forms/regular'),
      route('Extended Forms', null, 'forms/extended'),
      route('Validation Forms', null, 'forms/validation'),
      route('Wizard', null, 'forms/wizard'),

      // Tables
      route('Regular Tables', null, 'tables/regular'),
      route('Extended Tables', null, 'tables/extended'),
      route('Data Tables', null, 'tables/data-tables'),

      // Maps
      route('Google Maps', null, 'maps/google'),
      route('Fullscreen Map', null, 'maps/fullscreen'),

      route('Rtl', null, 'pages/rtl'),
      route('Widgets', null, 'widgets'),
      route('Charts', null, 'charts'),
      route('Calendar', null, 'calendar'),

      // Auth
      route('Logout', null, 'logout'),
    ]),
    layout('Page', [
      route('Error', null, 'error'),
      route('Lock', null, 'lock'),
      route('Login', null, 'login'),
      route('Pricing', null, 'pricing'),
      route('Register', null, 'register'),
      route('ForgotPassword', null, 'forgot-password'),
      route('ResetPassword', null, 'reset-password'),
      route('EmailVerification', null, 'email/verify'),
    ]),
  ],
})

/* router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
}) */

export default router
