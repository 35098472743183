// import axios from 'axios'

export default {
  namespaced: true,
  state: {
    processing: false,
  },
  getters: {
    getProcessing: state => state.processing,
  },
  mutations: {
    setProcessing: (state, payload) => state.processing = payload,
  },
  actions: {},
}
