import axios from 'axios'

export default {
  namespaced: true,
  state: {
    me: null,
  },
  getters: {
    getMe: state => state.me,
  },
  mutations: {
    setMe: (state, payload) => state.me = payload,
  },
  actions: {
    fetchMe: ({ commit }) => {
      return new Promise((resolve, reject) => {
        if (!localStorage.getItem('token')) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        }
        commit('global/setProcessing', true, { root: true })
        axios.get('/me')
          .then(response => {
            commit('setMe', response.data.data)
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem('token');
            reject(error);
          })
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    login: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/login', payload)
          .then(response => {
            localStorage.setItem('token', response.data.token)
            commit('setMe', response.data.user)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    forgotPassword: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/forgot-password', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    resetPassword: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/reset-password', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    logout: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/logout')
          .then(response => {
            localStorage.removeItem('token')
            commit('setMe', null)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    register: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.post('/register', payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }))
      })
    },
    verifyEmail: (context, payload) => new Promise((resolve, reject) => {
      const {
        user_id,
        hash,
        expires,
        signature,
      } = payload;
      const params = { expires, signature };
      axios.get(`/email/verify/${user_id}/${hash}`, { params })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    }),
    updateProfile: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('global/setProcessing', true, { root: true })
        axios.put('/me', payload)
          .then(response => {
            commit('setMe', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('global/setProcessing', false, { root: true }));
      })
    },
  },
}
