<template>
  <v-card
    class="focus-card mb-3"
    elevation="1"
    outlined
    shaped
  >
    <div class="focus-card__picture">
      <img
        :src="focus.picture.url"
        :alt="focus.label"
        :title="focus.label"
      />
    </div>
    <div class="focus-card__label">
      <a
        :href="focus.link"
        target="_blank"
      >
        {{ focus.label }}
      </a>
    </div>
    <div class="focus-card__publication-date">
      {{ $t('forms.published_at') }} : {{ focus.published_at ? $moment(focus.published_at).format('DD/MM/YYYY HH:mm') : $t('unpublished') }}
    </div>
    <div class="focus-card__actions">
      <div
        class="focus-card__action"
        @click="editFocus"
      >
        <i class="fas fa-edit"></i>
      </div>
      <div
        class="focus-card__action"
        @click="initiateFocusDelete"
      >
        <i class="fas fa-trash"></i>
      </div>
    </div>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
      class="focus-form-wrapper"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteFocus"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'FocusCardComponent',

  props: {
    focus: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showDeletionDialog: false,
  }),

  methods: {
    initiateFocusDelete() {
      this.showDeletionDialog = true;
    },
    closeDeletionDialog() {
      this.showDeletionDialog = false;
    },
    deleteFocus() {
      this.$store.dispatch('focus/deleteFocus', this.focus.id)
        .then(() => {
          this.$toasted.success(this.$t('item_deleted'));
          this.$emit('deleted');
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.closeDeletionDialog());
    },
    editFocus() {
      this.$emit('editing');
    },
  },
};
</script>

<style lang="scss">
.focus-card {
  width: 100%;
  overflow: hidden;

  &__picture {
    width: 100%;
    position: relative;
    padding-top: 75%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    padding: 10px;
    text-align: center;
  }

  &__actions {
    border-top: 1px solid #ececec;
    display: flex;
    justify-content: space-between;
  }

  &__action {
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7d8188;
    cursor: pointer;

    &:not(:first-child) {
      border-left: 1px solid #ececec;
    }

    &:last-child {
      cursor: grab;
    }
  }

  &__publication-date {
    font-size: 12px;
    text-align: center;
    opacity: .7;
  }
}
</style>
