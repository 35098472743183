<template>
  <div class="content-video-component">
    <VideoAdd
      v-model="videos"
      :max-files="1"
    />
  </div>
</template>

<script>
import VideoAdd from '../VideoAdd';
export default {
  name: 'ContentVideo',
  components: { VideoAdd },
  props: {
    value: {
      type: Object,
    },
  },

  data: () => ({
    videos: [],
  }),

  computed: {
    video() {
      if (!this.videos || !this.videos[0]) {
        return null;
      }
      return this.videos[0];
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.videos = [this.value];
      },
    },
    video: {
      deep: true,
      handler() {
        this.$emit('input', this.video);
      },
    },
  },

  created() {
    this.videos = this.value ? [this.value] : [];
  },
};
</script>
